#input-form {
    margin-left: 11vh;
    margin-right: 15vh;
    font-family: 'Stagegrotesk', sans-serif;
    font-size: 16px;
}

#project-details {
    width: 100vh;
    font-family: 'Stagegrotesk', sans-serif;
    font-size: 16px;
} 
#select-drop-down {
    font-family: 'Stagegrotesk', sans-serif;
    font-size: 16px; 
}

@media (max-width:700px) {
    #input-form {
        margin-left: 4vh;
    }
    #project-details {
        width: 100%;
    } 
}