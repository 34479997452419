#excavationVideo {
  width: 580px;
  height: 335px;
}

@media (max-width: 700px) {
  #excavationVideo {
    width: 90%;
    height: 100%;
  }
}
