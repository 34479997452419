#excavations {
  /* height: 80vh; */
  background-position: center;
  background-size: cover;
  /* width: 90vh; */
  /* padding-left: 50vh; */
}
#excavations-banner {
  padding-bottom: 5vh;
  margin-top: 5vh;
  font-size: 30px;
}
#excavations-text-content {
  font-family: "Stagegrotesk", sans-serif;
  font-size: 16px;
  margin-left: 10vh;
  margin-right: 18vh;
  margin-bottom: 10vh;
  font-family: "Roboto", sans-serif;
}
/* Base styles */
#MobileCarousel {
  display: block; /* Default display for mobile */
}
@media (max-width: 700px) {
  #excavations {
    height: auto;
  }

  #excavations-text-content {
    margin-left: 10vh;
    margin-right: 10vh;
  }
  #DesktopSlide {
    display: none;
  }
}
/* Media Query for Desktop View */
@media (min-width: 1024px) {
  #MobileCarousel {
    display: none; /* Hide on desktop screens */
  }
}
