#home {
  /* background-image:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://www.youtube.com/embed/B_qiquHUOhA?autoplay=1&loop=1&mute=1&controls=0&playlist=B_qiquHUOhA"); */
  /* background-image:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../photos/Big\ Excavator.jpg"); */
  height: 100vh;
  background-position: center;
  background-size: cover;
  margin-bottom: 3vh;
  background-color: white;
}
#text-box {
  padding-top: 5vh;
}
#text {
  color: black;
  font-family: "Roboto", sans-serif;
  padding-top: 3vh;
  font-size: 30px;
}

@media (max-width: 700px) {
  #home {
    height: 100vh;
  }
  #text-box {
    padding-top: 3vh;
  }
  #text {
    padding-top: 3vh;
    font-size: 21px;
  }
}
