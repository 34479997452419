.each-slide-effect {
  width: 100%;
  overflow: hidden; /* Hide overflow content */
  white-space: nowrap; /* Prevent content from wrapping */
  overflow: hidden; /* Hide overflow content */
}

.each-slide-effect > div {
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden; /* Hide overflow content */
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .each-slide-effect {
    padding-bottom: 75%; /* Adjust aspect ratio for mobile */
  }

  .each-slide-effect > div {
    background-size: cover;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .each-slide-effect span {
    padding: 10%;
    font-size: 16px;
  }
}
